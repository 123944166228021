<template>
  <div
    id="filters_action_bar"
    class="text-medium"
  >
    <strong>{{ $tc('filtre.filtre-actif', activeFilters.length) }}</strong>
    <div id="active_filters">
      <Tag
        v-for="filter in activeFilters"
        :key="filter.id"
        :tag-name="filter.libelle"
        delete-option
        @delete-tag="$emit('delete-tag', filter)"
      />
    </div>
    <div id="results_reset">
      <span>{{ $tc("recherche.x-resultat", totalResults, { resultat: totalResults, }) }}</span>
      <ButtonClassic
        :label="$t('action.reinitialiser')"
        variant="special"
        color="primary"
        icon="right"
        @click="$emit('reset-filters')"
      >
        <template #right-icon>
          <IconTrashReset />
        </template>
      </ButtonClassic>
    </div>
  </div>
</template>

<script>
import { ButtonClassic, Tag, IconTrashReset } from "@lde/core_lde_vue";

/**
 * Affiche une barre horizontale contenant des filtres de recherche.
 */
export default {
  name: "FiltersActionBar",
  components: {
    ButtonClassic,
    Tag,
    IconTrashReset,
  },
  props: {
    /**
     * Filtres actifs à afficher.
     */
    activeFilters: {
      type: Array,
      required: true,
    },
    /**
     * Total des résultats.
     */
    totalResults: {
      type: Number,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché à la suppression d'un filtre.
     * @param {Object} filter Filtre sélectionné.
     */
    "delete-tag",
    /**
     * Déclenché au clic sur le bouton de réinitialisation des filtres.
     */
    "reset-filters",
  ],
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/filters/filters_action_bar.scss";
</style>
