<template>
  <section id="expeditions_table">
    <TableClassic
      :columns="columns"
      :rows="rows"
      :active-sort="activeSort"
      @sort="$emit('sort', $event)"
    >
      <template #id-4d="{ value, }">
        {{ value.id_4d }}
      </template>
      <template #transporteur="{ value, }">
        {{ value.transporteur_verbose }}
      </template>
      <template #date="{ value, }">
        {{ value.date | moment($t("global.format-date")) }}
      </template>
      <template #conditionnement="{ value, }">
        {{ value.nb_elements }}
        <template v-if="value.conditionnement === 'P'">
          {{ $tc("expedition.palettes", value.nb_elements) }}
        </template>
        <template v-else>
          {{ $tc("expedition.colis") }}
        </template>
      </template>
      <template #statut="{ value, }">
        <TimelineLabel
          :label="defineStatusInfos(value).label"
          :tooltip="{
            title: defineStatusInfos(value).tooltip,
            placement: 'top',
          }"
          :status="defineStatusInfos(value).status"
          small
        />
      </template>
      <template #actions="{ value, }">
        <ButtonClassic
          v-tooltip="{
            content: $t('expedition.suivi-de-livraison'),
            delay: { show: 800, },
          }"
          balise-type="a"
          variant="special"
          color="primary"
          icon="left"
          :disabled="!value.suivi"
          :ext-link="value.suivi"
        >
          <template #left-icon>
            <UilLocationPoint />
          </template>
        </ButtonClassic>
        <ButtonClassic
          v-if="value.bonlivraisons.length <= 1"
          v-tooltip="{
            content: $tc('expedition.bons-de-livraison', value.bonlivraisons.length),
            delay: { show: 800, },
          }"
          balise-type="a"
          variant="special"
          color="primary"
          icon="right"
          :disabled="!value.bonlivraisons.length"
          :ext-link="value.bonlivraisons.length ? value.bonlivraisons[0].url_pdf : undefined"
        >
          <template #right-icon>
            <IconFileDelivery />
          </template>
        </ButtonClassic>
        <ButtonPopover
          v-else
          v-model="value._dropdownBL"
          v-tooltip="{
            content: $tc('expedition.bons-de-livraison', value.bonlivraisons.length),
            delay: { show: 800, },
          }"
          class="bon-livraison"
          color="primary"
          variant="special"
          placement="bottom-right"
          trigger="click"
        >
          <template #label>
            <IconFileDelivery size="16" />
            <Counter
              :value="value.bonlivraisons.length"
              size="small"
            />
          </template>
          <p class="bl-title">
            {{ $tc("expedition.bons-de-livraison", value.bonlivraisons.length) }}
          </p>
          <ul class="text-small">
            <li
              v-for="bl in value.bonlivraisons"
              :key="bl.id"
            >
              <a
                class="options-list-item-btn"
                :href="bl.url_pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                #{{ bl.id_4d }}
              </a>
            </li>
          </ul>
        </ButtonPopover>
        <ButtonPopover
          v-if="['view_commande', 'view_facture', ].some((perm) => hasPerm(perm))"
          v-model="value._dropdownLinks"
          color="primary"
          variant="special"
          icon="left"
          size="small"
          placement="bottom-right"
          trigger="click"
        >
          <template #left-icon>
            <UilEllipsisV size="12" />
          </template>
          <ul class="text-small">
            <li>
              <router-link
                v-if="hasPerm('view_commande')"
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_commandes', query: { expedition: value.id, }, }"
              >
                {{ $t("commande.commandes-liees") }}
              </router-link>
            </li>
            <li>
              <router-link
                v-if="hasPerm('view_facture')"
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_factures', query: { expedition: value.id, }, }"
              >
                {{ $t("commande.factures-liees") }}
              </router-link>
            </li>
          </ul>
        </ButtonPopover>
      </template>
    </TableClassic>
  </section>
</template>

<script>
import {
  ButtonClassic,
  ButtonPopover,
  Counter,
  ucFirst,
} from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";

import IconFileDelivery from "@/components/icons/IconFileDelivery.vue";

import { UilLocationPoint, UilEllipsisV } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche les expéditions de l'établissement courant.
 */
export default {
  name: "ExpeditionsTable",
  components: {
    ButtonClassic,
    ButtonPopover,
    Counter,
    TableClassic,
    TimelineLabel,
    IconFileDelivery,
    UilLocationPoint,
    UilEllipsisV,
  },
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Clé et sens du tri.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns: [
        {
          key: "id-4d",
          name: this.$t("expedition.numero-de-suivi"),
          sortKey: "id_4d",
        },
        {
          key: "transporteur",
          name: this.$t("expedition.transporteur"),
        },
        {
          key: "date",
          name: this.$t("expedition.expediee-le"),
        },
        {
          key: "conditionnement",
          name: this.$t("expedition.conditionnement"),
          // TODO: voir en back pour trier
          notSortable: true,
        },
        {
          key: "statut",
          name: this.$t("general.statut"),
        },
        {
          key: "actions",
          notSortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["hasPerm"]),
  },
  methods: {
    /**
     * Définit le statut de la ligne.
     * @param {Object} ligne Infos de la ligne.
     * @returns {Object} Label et son tooltip.
     */
    defineStatusInfos(ligne) {
      let status = "valid";
      let label;
      let tooltip;

      switch (ligne.statut_affiche) {
        case "attente_livraison":
          label = this.$t("timeline.expediee");
          tooltip = this.$t("timeline.expediee");
          break;
        case "livree":
          label = this.$t("timeline.livree");
          tooltip = this.$t("timeline.livree");
          break;
        default:
          label = ucFirst(ligne.statut_affiche.replace("attente_", ""));
          status = ligne.statut_affiche.indexOf("attente_") > -1 ? "awaiting" : null;
          tooltip = "";
          break;
      }

      return {
        label,
        status,
        tooltip,
      };
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/expeditions_table.scss";
</style>
